// extracted by mini-css-extract-plugin
export var videoFeatureIntro = "videoFeatureIntro-module--videoFeatureIntro--s-HtS";
export var videoFeatureIntroBackground = "videoFeatureIntro-module--videoFeatureIntroBackground--ViEIg";
export var videoFeatureWrapper = "videoFeatureIntro-module--videoFeatureWrapper--KBTlx";
export var videoFeatureContainer = "videoFeatureIntro-module--videoFeatureContainer--l-iP0";
export var videoFeatureIntroText = "videoFeatureIntro-module--videoFeatureIntroText--eIGAS";
export var spacer = "videoFeatureIntro-module--spacer--9pL3Z";
export var videoFeatureIntroTextHeader = "videoFeatureIntro-module--videoFeatureIntroTextHeader--qnsgo";
export var videoFeatureIntroTextContent = "videoFeatureIntro-module--videoFeatureIntroTextContent--ULtN7";
export var videoFeatureVideoWrapper = "videoFeatureIntro-module--videoFeatureVideoWrapper--WUrgS";
export var videoFeatureVideo = "videoFeatureIntro-module--videoFeatureVideo--KeAgB";
export var slideUp = "videoFeatureIntro-module--slideUp--NOjAd";
export var slideUpLarge = "videoFeatureIntro-module--slideUpLarge--5rpWw";