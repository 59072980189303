// extracted by mini-css-extract-plugin
export var sidebar = "timeline-module--sidebar--CUfcy";
export var slideIn = "timeline-module--slideIn--kf54Z";
export var timelineContent = "timeline-module--timelineContent--ZMAhE";
export var timelineBlock = "timeline-module--timelineBlock--0VMtV";
export var headerSmall = "timeline-module--headerSmall--05zLo";
export var headerMed = "timeline-module--headerMed--3WQTQ";
export var headerLarge = "timeline-module--headerLarge--3xbpw";
export var timelineWrap = "timeline-module--timelineWrap--BKFSl";
export var date = "timeline-module--date--4mELp";
export var label = "timeline-module--label--t1x5c";
export var timelineContentInner = "timeline-module--timelineContentInner--AGppv";
export var fadeIn = "timeline-module--fadeIn--eZgJ3";
export var slideUp = "timeline-module--slideUp--+coP7";
export var slideUpLarge = "timeline-module--slideUpLarge--0+pQh";