import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Footer from '../components/global/footer';
import PrimaryHeader from '../components/global/primaryHeader';
import SideMenu from '../components/global/sideMenu';
import '../style/main.scss';
import ieVersion from '../utils/ieVersion';
import { setPageLanguageUsingSettings } from '../utils/language';
import translations from '../utils/translations';

const getScrollAmount = () => {
	return window.pageYOffset || document.documentElement.scrollTop;
};

class VideoFeatureLayout extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			scrollHidden: false,
			scrollAmount: 0,
			hideToggle: true,
			showIntro: true,
			headerShadow: false,
			lang: 'en',
			isIE: ieVersion() !== 0,
			scrollY: 0,
      		megaMenuOpen: false,
		};
		this.scrollDirection = 'down';
		this.prevScrollDirection = 'down';
		this.amountInDirection = 0;
		if ( typeof window !== 'undefined' ) {
			this.scrollAmount = getScrollAmount();
		} else {
			this.scrollAmount = 0;
		}
	}

	componentDidMount() {
		window.addEventListener( 'scroll', this.handleScroll );

		if ( ieVersion() !== 0 ) {
			document.querySelector( 'body' ).className = 'isIE';
		}
		//Remove menu when "Back" button is clicked
		window.addEventListener( 'popstate', this.removeMenuDelay );
		setPageLanguageUsingSettings();
		translations.setLanguageSource('en', this.props.data.translations);
		translations.setLanguage('en');
		this.setState({ lang: 'en' });
	}

	componentWillReceiveProps( nextProps ) {
		//Always show header on route change
		if (
			this.props.location.pathname !== nextProps.location.pathname &&
			nextProps.location.hash.indexOf( '#region' ) === -1
		) {
			this.setState( { scrollHidden: false } );
		}
	}

	componentWillUnmount() {
		window.removeEventListener( 'scroll', this.handleScroll );
		window.removeEventListener( 'popstate', this.removeMenuDelay );
	}

	hideHeader = () => {
		this.setState( state => ( {
			scrollHidden: true,
			headerShadow: false,
			megaMenuOpen: false,
		} ) );
	};
	showHeader = () => {
		this.setState( state => ( {
			scrollHidden: false,
		} ) );
	};
	toggleMenu = param => {
		document.body.style.overflow = this.state.hideToggle ? 'hidden' : 'auto';
		this.setState( { hideToggle: !this.state.hideToggle } );
	};
	onToggleMegaMenu = () => {
		this.setState(prev => {
		  const newState = !prev.megaMenuOpen;
		  document.body.style.overflow = newState ? 'hidden' : 'auto';
		  
		  return {
			megaMenuOpen: newState
		  };
		});
	  }
	removeMenu = param => {
		document.body.style.overflow = 'auto';
		this.setState( { hideToggle: true } );
	};
	removeMenuDelay = () => {
		setTimeout( this.removeMenu, 350 );
	};
	handleScroll = e => {
		if ( this.props.location.hash.indexOf( '#region' ) !== -1 ) {
			return;
		}

		// no scroll handling on bomb blast
		const newScrollAmount = getScrollAmount();
		this.prevScrollDirection = this.scrollDirection;
		this.scrollDirection = newScrollAmount > this.scrollAmount ? 'down' : 'up';
		const scrollDif = newScrollAmount - this.scrollAmount;

		if ( this.prevScrollDirection === this.scrollDirection ) {
			this.amountInDirection += scrollDif;
		} else {
			this.amountInDirection = 0;
		}

		if ( newScrollAmount < 100 && this.state.headerShadow ) {
			this.setState( { headerShadow: false } );
		}
		if ( this.props.location.pathname.indexOf( 'bomb-blast' ) !== -1 ) {
			return;
		}

		if ( newScrollAmount <= 100 && this.state.scrollHidden ) {
			// if (this.props.location.hash)
			this.showHeader();
			return;
		}

		if (
			newScrollAmount + window.innerHeight >=
			document.body.scrollHeight - 250
		) {
			this.setState( { headerShadow: true } );
			this.showHeader();
			return;
		}

		if (
			newScrollAmount > this.scrollAmount &&
			this.amountInDirection > 30 &&
			!this.state.scrollHidden &&
			newScrollAmount > 100
		) {
			this.hideHeader();
		} else if (
			newScrollAmount < this.scrollAmount &&
			this.amountInDirection < -30 &&
			this.state.scrollHidden
		) {
			this.setState( { headerShadow: true } );
			this.showHeader();
		}

		this.scrollAmount = newScrollAmount;
		this.setState({ scrollY: getScrollAmount() });
	};

	//format data in more friendly way for components
	extractData(data) {
		let formattedData = [];
		if(typeof data !== 'undefined' && typeof data['edges'] !== 'undefined') {
			formattedData = data['edges'].map(edge => edge.node);
		}
		return formattedData[0];
	};
	
	render() {
		const noFooter =
			this.props.location.pathname.indexOf( 'interactive' ) !== -1 ||
			this.props.location.pathname.indexOf( 'climateTrends' ) !== -1;
		const extendedHeader = this.props.location.pathname.indexOf( 'archive' ) > -1;
		const blogURL = (this.props.blog) ? this.props.blog.edges[0].node.path : '';
		const footerData = this.props.data[this.props.pageContext.isEs ? 'footerEs' : 'footer'];

		
		return (
			<div
				className={`${this.state.isIE ? 'isIE' : ''}`}
			>
				<PrimaryHeader
					key={`header`}
					hide={this.state.scrollHidden}
					isHidden={this.state.hideToggle}
					menuClick={this.toggleMenu}
					removeMenuClick={this.removeMenuDelay}
					activePath={this.props.location.pathname}
					headerData={this.extractData(this.props.data.header)}
					headerShadow={this.state.headerShadow}
					headerChallenges={this.props.data.challenges}
					isIE={this.state.isIE}
					blogData={this.props.data.blog}
					scrollY={this.state.scrollY}
					onToggleMegaMenu={this.onToggleMegaMenu}
					megaMenuOpen={this.state.megaMenuOpen}
					megaMenuData={this.props.data.megaMenu}
					menuSocial={footerData.edges[0].node.menu_social}
				/>
				<main
					className={`sitewrap video-feature-wrapper ${this.state.hideToggle ? '' : 'overlay-blur'} ${extendedHeader ? 'extended-header' : ''}`}
				>
					<div className="video-feature-content">
						{this.props.children}
					</div>
				</main>
				{!noFooter && <Footer isExtended={true} blogData={this.props.data.blog} {...this.props.data.footer} />}
			</div>
		);
	}
}

export default VideoFeatureLayout;

// eslint-disable-next-line
/* export const VideoFeatureLayoutQuery = graphql`
query videoFeatureContent {
  ...headerFields
  ...footerFields
  challenges: allItemsJson(filter: {content_type: {regex: "/timeline|article|challenge/"}, id: {regex: "/^en-/"}}) {
    edges {
      node {
        title
        content_type
        path
        related_challenge {
          title
          path
        }
      }
    }
  }
  pages: allItemsJson(filter: {content_type: {eq: "page"}, id: {regex: "/^en-/"}}) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  blog: allItemsJson(filter: { id: { eq: "en-122" } }) {
    edges {
      node {
        title
        content_type
        path
      }
    }
  }
  translations:  allItemsJson(filter: {id: {eq: "en-11"}}) {
      edges {
        node {
         translations {
            id
            key
            value
          }
        }
      }
    }
}
`; */
