// extracted by mini-css-extract-plugin
export var videoEmbed = "videoEmbed-module--videoEmbed--QYlW4";
export var featured = "videoEmbed-module--featured--9qxzu";
export var videoContainer = "videoEmbed-module--videoContainer--Lz482";
export var inline = "videoEmbed-module--inline--90WJZ";
export var videoWrap = "videoEmbed-module--videoWrap--rBxot";
export var fullScreen = "videoEmbed-module--fullScreen--Cca0r";
export var videoCover = "videoEmbed-module--videoCover--vqKfD";
export var inlineVideo = "videoEmbed-module--inlineVideo--FrCK-";
export var coverImage = "videoEmbed-module--coverImage--AL4u9";
export var embed = "videoEmbed-module--embed--5uDfa";
export var play = "videoEmbed-module--play--JEEeM";
export var centerPlay = "videoEmbed-module--centerPlay--qlnT2";
export var coverText = "videoEmbed-module--coverText--nzinG";
export var articleVid = "videoEmbed-module--articleVid--Wn0S0";
export var coverTextPlaying = "videoEmbed-module--coverTextPlaying--73jdj videoEmbed-module--coverText--nzinG";
export var shortDesc = "videoEmbed-module--shortDesc--mFQJh";
export var shortDescInner = "videoEmbed-module--shortDescInner--m80nM";
export var small = "videoEmbed-module--small--afl4N";
export var videoEmbedTimeline = "videoEmbed-module--videoEmbedTimeline--QSOll videoEmbed-module--videoEmbed--QYlW4";
export var slideUp = "videoEmbed-module--slideUp--yWhDx";
export var slideUpLarge = "videoEmbed-module--slideUpLarge--EoR0g";