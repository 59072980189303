import * as style from '../basic/videoEmbed.module.scss';

import React from 'react';

const getVideoSrc = (url, id, startTime, allowFullscreen, canPlayUploadedVideos, autoplay = 1) => {
  if(id.charAt(2) === '-') {
    id = id.substring(3);
  }
  if (
    typeof startTime.secs !== 'undefined' &&
    typeof startTime.mins === 'undefined'
  ) {
    startTime.mins = 0;
  }
  if (
    typeof startTime.mins !== 'undefined' &&
    typeof startTime.secs === 'undefined'
  ) {
    startTime.secs = 0;
  }
  if (url.includes('vimeo')) {
    return `https://player.vimeo.com/video/${id}?autoplay=${autoplay}${
      typeof startTime.mins !== 'undefined'
        ? `#t=${startTime.mins}m${startTime.secs}s`
        : ''
    }`;
  } else if (url.includes('youtu')) {
    const fs = (allowFullscreen) ? 1 : 0;
    return `https://www.youtube.com/embed/${id}?enablejsapi=1&rel=0&amp;showinfo=0;autoplay=${autoplay};fs=${fs}${
      typeof startTime.mins !== 'undefined'
        ? `;start=${parseInt(startTime.mins, 10) * 60 +
            parseInt(startTime.secs, 10)}`
        : ''
    }`;
  }
  if(canPlayUploadedVideos) {
    return url;
  }
  console.error('Bad video url format', url);
};

class VideoPlayer extends React.Component {
  componentDidMount() {
    if (typeof this.props.onVideoComplete !== 'undefined') {
      if (!document.getElementById('youtubeApi')) {
        const tag = document.createElement('script');
        tag.id = 'youtubeApi';
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
          /* eslint-disable-next-line no-undef */
          this.player = new YT.Player(`embeddedVideo${this.props.vidId}`, {
            events: {
              onStateChange: this.onPlayerStateChange,
            },
          });
        };
      } else {
          /* eslint-disable-next-line no-undef */
        this.player = new YT.Player(`embeddedVideo${this.props.vidId}`, {
          events: {
            onStateChange: this.onPlayerStateChange,
          },
        });
      }
    }
  }
  onPlayerStateChange = event => {
    if (event.data === 0) {
      this.props.onVideoComplete();
    }
  };
  render() {
    return (
      <div
        className={`${style.videoContainer} ${
          this.props.fullScreen ? style.fullScreen : style.inline
        } `}
      >
        <div className={style.videoWrap}>
          <iframe
            id={
              this.props.onVideoComplete
                ? `embeddedVideo${this.props.vidId}`
                : ''
            }
            title={this.props.headline}
            className={`${style.embed} ${this.props.videoStyle}`}
            src={getVideoSrc(this.props.videoUrl, this.props.videoId, {
              mins: this.props.startTimeMinutes,
              secs: this.props.startTimeSeconds,
            }, this.props.allowFullScreen, this.props.canPlayUploadedVideos, this.props.autoplay)}
            frameBorder="0"
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}
export default VideoPlayer;
