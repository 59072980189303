import { graphql } from 'gatsby';
import jump from 'jump.js';
import React, { Component } from 'react';
import VideoFeatureIntro from '../components/feature/videoFeatureIntro';
import HeadTags from '../components/global/headTags';
import videofeatureMapDataToComponents from './videofeatureMapDataToComponents';
import FeatureLayout from '../layouts/feature-layout';

class VideoFeature extends Component {
	constructor() {
		super();
		this.state = {
			startPage: false,
			isExiting: false,
			width: 0, 
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	
	componentWillReceiveProps(nextProps) {
		// issue where first page click doesn't transition properly, so manually doing
		// transition here.
		if (nextProps.transition && nextProps.transition.status === 'exiting') {
			this.setState({ isExiting: true });
		}
	}
	
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		this.setState({
			isExiting: false,
		});
		setTimeout(this.startPage, 1000);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}
	
	startPage = () => {
		this.setState({ startPage: true });
	};
	
	render() {
		const {
			title,
			intro_description,
			intro_heading,
			meta_description,
			meta_image,
			short_description,
			featured_image,
			featured_video,
			video,
			video_embed,
			paragraphs,
		} = this.props.data.allItemsJson.edges[0].node;
		const props = this.props;
		const exploreClick = () => {
			jump('#content');
		};
		let headerImage = null;
		let headerTwitterImage = null;

		if(meta_image) {
			headerImage = meta_image.sizes.hero_md;
			headerTwitterImage = meta_image.sizes.hero_sm;
		} else {
			headerImage = featured_image.sizes.hero_md;
			headerTwitterImage = featured_image.sizes.hero_sm;
		}

		return (
			<FeatureLayout {...props} excludeParentFunctions>
				<div className="video-feature-content-wrapper">
					<HeadTags
						title={title}
						description={meta_description}
						image={headerImage}
						imageTwitter={headerTwitterImage}
						location={props.location}
					/>
					<div>
						{featured_video &&
							<div>
								<VideoFeatureIntro 
									heading={intro_heading}
									description={intro_description}
									video={featured_video}
									backgroundImage={featured_image}
								/>
							</div>
						}
					</div>
					<div className="article-content">
						<div className="text-block intro-text" dangerouslySetInnerHTML={{__html: short_description}} />
						{videofeatureMapDataToComponents(paragraphs)}
					</div>
				</div>
			</FeatureLayout>
		);
	}
}

export default VideoFeature;

// eslint-disable-next-line
export const VideoFeaturePageQuery = graphql`
query videoFeaturePageContentByPath(
	$path: String!
	$menuId: String!
	$idRegex: String!
	$isEs: Boolean!
	$blog: String!
	$translations: String!
  ) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
		  path
		  intro_description
		  intro_heading
		  meta_description
		  meta_image {
			sizes {
			  hero_md
			  hero_sm
			}
		  }
		  short_description
		  title
		  related_challenge {
			id
			title
			path
		  }
		  featured_image {
			image {
			  sizes {
				hero_lg
				hero_md
				hero_sm
				original
			  }
			}
		  }
		  featured_video {
			caption
			url
			id
			image {
			  title
			  sizes {
				original
				standard_md
				standard_sm
			  }
			}
		  }
		  video {
			url
		  }
		  video_embed {
			id
			url
			name
		  }
		  ...queryParagraphFields
		}
	  }
	}
	social: allItemsJson(filter: { id: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
  
	# Index Content Data
	...headerFields @skip(if: $isEs)
	...footerFields @skip(if: $isEs)
	...headerFieldsEs @include(if: $isEs)
	...footerFieldsEs @include(if: $isEs)
	challenges: allItemsJson(
	  filter: {
		content_type: { regex: "/timeline|article|challenge/" }
		jsonId: { regex: $idRegex }
	  }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		  related_challenge {
			title
			path
		  }
		}
	  }
	}
	pages: allItemsJson(
	  filter: { content_type: { eq: "page" }, jsonId: { regex: $idRegex } }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	blog: allItemsJson(filter: { jsonId: { eq: $blog } }) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	translations: allItemsJson(filter: { jsonId: { eq: $translations } }) {
	  edges {
		node {
		  translations {
			id
			key
			value
		  }
		}
	  }
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }
  
`;