import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VideoBackground from './videoBackground';
import VideoPlayer from '../ui/videoPlayer';
import CircleButton from '../ui/circleButton';
import * as style from './videoEmbed.module.scss';
import * as timelineStyle from '../timeline/timeline.module.scss';

const VideoCover = props => (
  <div
    className={`${style.videoContainer} ${style.inline} ${
      props.isTimeline || props.isArticle ? ' reveal fadeInUp' : ''
    }`}
  >
    <div className={style.videoWrap}>
      <div className={style.videoCover}>
        {props.videoBackground ? (
          <div className={style.inlineVideo}>
            <VideoBackground videoStyle="cover" src={props.videoBackground} />
          </div>
        ) : (
          <div
            className={style.coverImage}
            style={{ backgroundImage: `url(${props.imgSrc})` }}
          />
        )}
      </div>
      <div className={props.isFeatured ? style.centerPlay : style.play}>
        {props.isFeatured &&
          <CircleButton type="play" handleClick={props.handlePlay} biggest />
        }
        {!props.isFeatured &&
          <CircleButton type="play" handleClick={props.handlePlay} />
        }
      </div>
    </div>
  </div>
);

class VideoEmbed extends Component {
  constructor() {
    super();
    this.state = {
      showVideo: false,
    };
  }
  componentDidMount() {
    if (this.props.showVideo === true) {
      this.setState({ showVideo: true });
    }
  }
  handlePlay = () => {
    this.setState({ showVideo: true });
  };
  render() {
    const allowFullScreen = (this.props.isFeatured) ? true : false;
    const cover = <VideoCover {...this.props} handlePlay={this.handlePlay} />;
    const player = <VideoPlayer {...this.props} allowFullScreen={allowFullScreen} fullScreen={false} autoplay={this.props.showVideo === true ? 0 : 1} />;

    return (
      <div
        className={`${this.props.small ? style.small : ''} ${this.props
          .isArticle && style.articleVid}
        ${this.props.isTimeline ? style.videoEmbedTimeline : style.videoEmbed}
        ${this.props.isFeatured ? style.featured : ''}`}
      >
        {this.state.showVideo ? player : cover}

        <div
          className={`
            ${this.state.showVideo ? style.coverTextPlaying : style.coverText}

          `}
        >
          <div
            className={
              this.props.isTimeline || this.props.isArticle
                ? 'fadeInBlur reveal reveal-delay-500 '
                : ''
            }
          >
            {this.props.date && (
              <span className={timelineStyle.date}>{this.props.date}</span>
            )}
            <h2 className={timelineStyle.headerMed}>{this.props.heading}</h2>
            {this.props.text && (
              <div className={style.shortDesc}>
                <div
                  className={style.shortDescInner}
                  dangerouslySetInnerHTML={{ __html: this.props.text }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
VideoEmbed.propTypes = {
  heading: PropTypes.string.isRequired,
  date: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  videoBackground: PropTypes.string,
  text: PropTypes.string,
};

export default VideoEmbed;
