import React, {Suspense} from 'react';
import Loading from '../components/global/loading';
import Reveal from '../components/ui/reveal';

const ArticleList = React.lazy(() => import('../components/feature/articleList'));
const ArticleCarousel = React.lazy(() => import('../components/article/articleCarousel'));
const ImageCallout = React.lazy(() => import('../components/timeline/imageCallout'));
const Promo = React.lazy(() => import('../components/magazine/promo'));
const VideoWithQuote = React.lazy(() => import('../components/feature/videoWithQuote'));

const videofeatureMapDataToComponents = (paragraphs) => {
	const isSSR = typeof window === "undefined";
	let skip = 0;
	let timelineImageIndex = 0;
	let hasFrame = false;
	const paragraphData = (
		paragraphs &&
		paragraphs.map((p, i) => {
			if(p.type !== 'timeline_image_text') {
				timelineImageIndex = 0;
			}
			if(skip === 0) {
				switch (p.type) {
					case 'timeline_image_text':
						hasFrame = timelineImageIndex % 2 === 0;
						timelineImageIndex += 1;
						return (
							<Reveal key={`articleItem${p.type}${i}`}>
								<ImageCallout
									src={p.image ? p.image.sizes.landscape_sm : ''}
									imgDimensions={p.image ? p.image.dimensions.landscape_sm : ''}
									altText={p.image ? p.image.alt_text : ''}
									heading={p.callout_heading}
									text={p.text}
									date={p.timeline_info ? p.timeline_info.year : null}
									cta_link={p.cta_link}
									image_alignment_type={p.image_alignment_type}
									isFramed={hasFrame}
								/>
							</Reveal>
						);
					case 'timeline_carousel':
						return (
							<Reveal key={`articleItem${p.type}${i}`}>
								<ImageCallout
									src={p.image ? p.image.sizes.landscape_sm : ''}
									imgDimensions={p.image ? p.image.dimensions.landscape_sm : ''}
									altText={p.image ? p.image.alt_text : ''}
									heading={p.callout_heading}
									text={p.text}
									date={p.timeline_info ? p.timeline_info.year : null}
									cta_link={p.cta_link}
									image_alignment_type={p.image_alignment_type}
									items={p.images.map((item, i) => ({
										src: item.sizes.fixed_height_portrait,
										altText: item.alt_text,
										caption: item.caption,
										credit: item.credit,
									}))}
								/>
							</Reveal>
						);
					case 'image_carousel':
						return (
							<Reveal key={`articleItem${p.type}${i}`}>
								<ArticleCarousel {...p} />
							</Reveal>
						);
					case 'article_list':
						return (<ArticleList 
							key={`articleItem${p.type}${i}`}
							label={p.label}
							articles={p.items}
						/>);
					case 'grid_text_callout':
						if(p.image_width_type !== 'tall') {
							return (<Promo key={`articleItem${p.type}${i}`} promo={p} size="large" />);
						} else {
							const items = formatParagraphs(getCalloutsStartingAtIndex(paragraphs, i));
							skip = items.length - 1;
							return (<ArticleList 
								key={`articleItem${p.type}${i}`}
								articles={items}
							/>);
						}
					case 'video_with_quote':
						return (
							<Reveal key={`articleItem${p.type}${i}`}>
								<VideoWithQuote {...p} />
							</Reveal>
						);
					default:
						return null;
				}
			}
			if(skip > 0) {
				skip -= 1;
			}
		})
	);

	return paragraphData.map((paragraph, index) => {
		return(
			<>
			{!isSSR && (
				<Suspense key={`suspense${index}`} fallback={<Loading/>}>
					{paragraph}
				</Suspense>
			)}
			</>
		);
	});
};

function formatParagraphs(paragraphs) {
	const paragraphResults = [];
	let paragraphGroup = [];
	paragraphs.forEach((p, i) => {
		if(p.type === 'grid_text_callout' && p.image_width_type === 'tall') {
			p.short_description = p.text;
			p.content_type_label = p.label;
		}
		paragraphResults.push(p);
	})
	return paragraphResults;
}

function getCalloutsStartingAtIndex(paragraphs, index) {
	let paragraphGroup = [];
	for(let i = index; i < paragraphs.length; i++) {
		const p = paragraphs[i];
		const nextP = paragraphs[i + 1];
		if(p.type === 'grid_text_callout' && p.image_width_type === 'tall') {
			paragraphGroup.push(p);
		}
		if(!(nextP.type === 'grid_text_callout' && nextP.image_width_type === 'tall')) {
			i = paragraphs.length;
		}
	}
	return paragraphGroup;
}

export default videofeatureMapDataToComponents;
